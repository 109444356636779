// Theme
$main-green : #65F869;
$background-dark : #0c0c0c;

// Stars
$gray-star : #C2C2C2;
$gold-star : #FCB300;

// Chips
$wishlist-chip : #FDFD83;
$finished-chip : #76BDFC;
$progress-chip : #DA68E4;

// Heart
$favorite : red;
$not-favorite : rgb(191, 191, 191);

// Extremes
$white : rgb(255, 255, 255);
$black : rgb(0, 0, 0);