.login-screen {
    display: flex;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .login-box {
        display: flex;
        flex-direction: column;
        padding: 15px 10%;
        gap: 10px;
        width: 100%;
        max-width: 500px;
        font-size: larger;

        input {
            padding: 5px;
            border-radius: 4px;
        }
    }

    button {
        padding: 10px 15px;
        border-style: none;
        border-radius: 8px;
    }
}