@import '../../styles/Colors.scss';

.stars {
    display: flex;
    flex-direction: row;
    font-size: x-large;
    cursor: pointer;

    .gray {
        color: $gray-star;
        margin-right: 5px;
        transition: 0.15s ease-out;
    }

    .gold {
        color: $gold-star;
        margin-right: 5px;
        transition: 0.15s ease-out;
    }
}