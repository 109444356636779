@import '../../styles/Colors.scss';

.not-favorite {
    color: $not-favorite;
    font-size: x-large;
    cursor: pointer;
}

.favorite {
    color: $favorite;
    font-size: x-large;
    cursor: pointer;
}
