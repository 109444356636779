.menu-bar {
    background-color: rgb(12, 12, 12);
    color: white;
    height: 100vh;
    position: fixed;
    width: 200px;

    .logo {
        height : 150px;
        background-color: rgb(71, 199, 84);
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .links {
        display: flex;
        flex-direction: column;
        margin-right: 10px;

        .link {
            padding: 10px 10px;
            margin-bottom: 10px;
            text-decoration: none;
            font-size: large;
            cursor: pointer;
            color: inherit;
        }

        .selected {
            background-color: rgb(78, 232, 93);
            border-radius: 0 7px 7px 0;
        }
    }
}


@media(max-width: 768px) {
    .menu-bar {
        width: 100vw;
        height: min-content;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 5px 0;
        
        .logo {
            display: none;
        }

        .links {
            flex-direction: row;

            .selected {
                border-radius: 0;
            }
        }
    }
}

@media(max-width: 425px) {
    .menu-bar {
        .links {
            .link {
                font-size: small;
            }
        }
    }
}