@import '../styles/Colors.scss';

.content-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    padding : 5px;
}

.media-title {
    font-size: 3.5rem;
}

.button-container {
    justify-content: flex-end;
}

.add-button {
    padding: 7px 10px;
    background-color: $main-green;
    font-size: x-large;
    border-radius: 8px;
    border-style: none;
    cursor: pointer;
}

.content {
    padding : 30px 30px 30px 230px;
    overflow-y: scroll;
}

@media(max-width: 768px) {
    .content {
        padding: 0;
    }

    .content-list {
        justify-content: space-evenly;
    }

    .button-container {
        justify-content: center;
    }
}

@media(max-width: 425px) {
    .media-title {
        padding-left: 15px;
    }
}
