@import '../../styles/Colors.scss';

.chips {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 15px 0px;

    .chip {
        padding: 7px 10px;
        border-radius: 20px;
        margin: 0 5px 0 0;
        font-weight: 500;
    }
}

.owned {
    background-color: $main-green;
}

.wishlisted {
    background-color: $wishlist-chip;
}

.completed {
    background-color: $main-green;
}

.finished {
    background-color: $finished-chip;
}

.in_progress {
    background-color: $progress-chip;
}