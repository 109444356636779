.title {
    font-size: x-large;
    font-weight: bold;
}

.title-input {
    input {
        font-size: x-large;
        font-weight: bold;
        border: none;
        width: 100%;

        &:focus &:active {
            border: none;
        }
    }
}
