@import '../../styles/Colors.scss';

.detail-card {
    position: relative;
    z-index: 1;
    color: $black;
    background-color: $white;
    cursor: default;
    border-radius: 11px;
    padding: 10px;
    min-width: 100%;
    transform: translateX(-100%);
    display: flex;
    flex-direction: column;

    .card-heading {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .description {
        font-weight: lighter;
        word-wrap: normal;
        font-size: large;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 0 5px;
        overflow-y: scroll;
    }

    .button-panel {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .play-button {
            font-size: x-large;

            .active {
                cursor: pointer;
                color: $main-green;
            }

            .inactive {
                cursor: not-allowed;
                color: gray;
            }
        }

        .edit-button {
            font-size: x-large;
            color: gray;
            cursor: pointer;
            background-color: transparent;
            border: none;
            transition-duration: 0.25s;

            &:hover {
                color: lightblue;
            }
        }
    }
}

.hide {
    display: none;
}

.show {
    display: flex;
}