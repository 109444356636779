@import '../../../styles/Colors.scss';

.edit-popup {
    position: absolute;
    z-index: 10;
    background-color: $main-green;
    bottom: 0;
    display: flex;
    flex-direction: column;
    bottom: 20%;
    padding: 15px 20px;
    border-radius: 8px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.404);
    height: 400px;
    justify-content: space-around;

    .heading {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    
        .title {
            font-size: x-large;
            font-weight: bold;
        }

        .close {
            background-color: transparent;
            border: none;
            font-size: x-large;
            color: black;
            cursor: pointer;
            transition-duration: 0.5s;

            &:hover {
                color: red;
            }
        }
    }

    .media-title {
        font-family: sans-serif;
    }

    input, select, textarea {
        padding: 5px;
        border-radius: 5px;
        border-style: solid;
        border-width: 1px;
        font-family: sans-serif;
    }

}