@keyframes spinnyBoi {
    from { rotate: 0deg ;}
    to { rotate: 360deg ;}
}

.spinner {
    font-size: 10rem;
    animation-name: spinnyBoi;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}