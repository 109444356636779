@import '../../styles/Colors.scss';

.add-sidebar {
    display: flex;
    flex-direction: column;
    position: fixed;
    top : 0;
    right : 0;
    height: 100vh;
    width: 300px;
    z-index: 10;
    background-color: $background-dark;
    color: $white;
    padding: 30px;
    transition: 0.5s ease-in-out;

    .title {
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .heading {
            font-size: xx-large;
            font-weight: bold;
        }

        .button {
            border-style: none;
            background-color: transparent;
            color: $white;
            font-size: xx-large;
            font-weight: bold;
            transition: 0.25s ease-in-out;
            cursor: pointer;

            &:hover {
                color: red;
            }
        }
    }

    input, select {
        border-radius: 8px;
        padding: 5px;
        margin-bottom: 15px;
        font-size: larger;
        font-family: sans-serif;
    }

    .submit-button {
        border-style: none;
        border-radius: 8px;
        padding: 10px;
        cursor: pointer;
        background-color: $main-green;
        font-size: larger;

        &:hover {
            background-color: green;
        }
    }
}

.open {
    transform: translateX(0%);
}

.close {
    transform: translateX(100%);
}