.list-item {
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    max-width: 700px;
    /* min-width: 700px;*/
    margin: 10px 10px;
    border-radius: 11px;
    cursor: pointer;
    height: 400px;
    width: 250px;

    .thumbnail {
        img {
            width: 250px;
            height: 400px;
            background-color: rgba(0, 0, 0, 0.734);
            border-radius: 11px;
        }
    }

    .text {
        padding: 15px 225px;

        .title {
            font-size: larger;
            font-weight: bolder;
            padding: 0 0 10px 0;
        }
    }
}