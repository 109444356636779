.book {
    font-weight: lighter;
}

.game {
    font-weight: lighter;
}

.show {
    font-weight: lighter;
}
